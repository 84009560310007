import { MutationOptions } from 'react-query';
import { connectKakaoWithAuth, loginKakao, signUpKakao } from 'api2';
import {
  ConnectKakaoWithAuth_connectKakaoWithAuth,
  ConnectKakaoWithAuthInput,
  LoginKakao_loginKakao,
  LoginKakaoInput,
  SignUpKakao_signupKakao,
  SignupKakaoInput,
} from 'api2/types';

export const loginKakaoMutationOption: MutationOptions<LoginKakao_loginKakao, unknown, LoginKakaoInput> = {
  mutationFn: async (input: LoginKakaoInput) => {
    const { data } = await loginKakao({ input }, { show_alert: false });
    return data.loginKakao;
  },
  mutationKey: 'loginKakao',
};

export const connectKakaoWithAuthMutationOption: MutationOptions<
  ConnectKakaoWithAuth_connectKakaoWithAuth,
  unknown,
  ConnectKakaoWithAuthInput
> = {
  mutationFn: async (input: ConnectKakaoWithAuthInput) => {
    const { data, errors } = await connectKakaoWithAuth({ input }, { show_alert: false });
    if (errors) {
      throw errors;
    }
    return data.connectKakaoWithAuth;
  },
  mutationKey: 'connectKakaoWithAuth',
};

export const signupKakaoMutationOption: MutationOptions<SignUpKakao_signupKakao, unknown, SignupKakaoInput> = {
  mutationFn: async (input: SignupKakaoInput) => {
    const { data } = await signUpKakao({ input }, { show_alert: false });
    return data.signupKakao;
  },
  mutationKey: 'signupKakao',
};
