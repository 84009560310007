import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useAuthWebEvent } from 'components/auth/hooks/log';
import { useLoginErrors } from 'components/auth/hooks/login';
import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage';
import { default as useInterval } from 'hooks/useInterval';
import { useRouter } from 'next/router';
import { loginGoogleMutationOption } from 'queries/naver-shopping';

import { AuthInfo, AuthSessionStorage } from '@domains/auth-common';

import LoadingManager from 'util/loading_manager';
import { checkXSSUrl } from 'util/router';

// eslint-disable-next-line no-var
declare var STAGE: string;

interface InitConfig {
  client_id: string;
  callback: (handleCredentialResponse: CredentialResponse) => void;
}

export interface CredentialResponse {
  credential: string;
  select_by?:
    | 'auto'
    | 'user'
    | 'user_1tap'
    | 'user_2tap'
    | 'btn'
    | 'btn_confirm'
    | 'brn_add_session'
    | 'btn_confirm_add_session';
  clientId?: string;
}

interface ButtonConfig {
  type: 'standard' | 'icon';
  theme?: 'outline' | 'filled_blue' | 'filled_black';
  size?: 'large' | 'medium' | 'small';
  text?: 'signin_with' | 'signup_with' | 'continue_with' | 'signup_with';
  shape?: 'rectangular' | 'pill' | 'circle' | 'square';
  logo_alignment?: 'left' | 'center';
  width?: number;
  locale?: string;
  click_listener?: () => void;
}

interface Google {
  accounts: {
    id: {
      initialize: (input: InitConfig) => void;
      renderButton: (parent: HTMLElement, options: ButtonConfig) => void;
    };
  };
}

declare global {
  interface Window {
    google?: Google;
  }
}

const CLIENT_ID = {
  alpha: '1026523051672-g7rm003o0tco2lgsu6mke1j1ip7f63v9.apps.googleusercontent.com',
  beta: '1026523051672-a8jfdg55k30cvnslrissnpumg8kkvis0.apps.googleusercontent.com',
  production: '1026523051672-tn7049fcov8kklua0p9lkq6dhs0qgmia.apps.googleusercontent.com',
};

export const useGoogleLogin = (button_config: ButtonConfig) => {
  const { push } = useRouter();
  const { mutateAsync: loginGoogleMutate } = useMutation(loginGoogleMutationOption);
  const { checkLoginError } = useLoginErrors();
  const { addSocialLoginEvent } = useAuthWebEvent();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setEmail] = useSessionStorage('email', '');

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [google, setGoogle] = useState<Google | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useInterval(
    () => {
      if (typeof window !== 'undefined' && window.google) {
        setGoogle(window.google);
      }
    },
    google === null ? 100 : null,
  );

  useEffect(() => {
    const init = async () => {
      const client_id = CLIENT_ID[STAGE] ?? CLIENT_ID.alpha;
      await window.google?.accounts.id.initialize({
        client_id,
        callback: handleCallbackResponse,
      });
      setIsInitialized(true);
    };

    if (google) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google]);

  useEffect(() => {
    if (containerRef && isInitialized) {
      window.google?.accounts.id.renderButton(containerRef, button_config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, isInitialized]);

  const handleCallbackResponse = (response: CredentialResponse) => {
    const token = response.credential;
    loginWithGoogle(token);
  };

  const loginWithGoogle = async (id_token: string) => {
    if (typeof window.google === 'undefined') {
      return console.error('google 라이브러리를 불러오는 데 실패하였습니다.');
    }

    const token = id_token;
    const go_url = AuthSessionStorage.getRedirectUrl();

    try {
      LoadingManager.open();
      const { user_account } = await loginGoogleMutate({ token });
      addSocialLoginEvent({ email: user_account.email, uuid: user_account.uuid }, 'google');
      setEmail(user_account.email);
      push(checkXSSUrl(go_url));
    } catch (error) {
      AuthSessionStorage.saveRedirectUrl(go_url);
      const auth_info: AuthInfo = {
        type: 'google',
        token,
      };
      checkLoginError(error, auth_info);
      LoadingManager.close();
    }
  };

  return { setContainerRef, containerRef };
};
