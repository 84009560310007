import { MutationOptions } from 'react-query';
import { connectGoogleWithAuth, loginGoogle, signUpGoogle } from 'api2';
import {
  ConnectGoogleWithAuth_connectGoogleWithAuth,
  ConnectGoogleWithAuthInput,
  LoginGoogle_loginGoogle,
  LoginGoogleInput,
  SignUpGoogle_signupGoogle,
  SignupGoogleInput,
} from 'api2/types';

export const loginGoogleMutationOption: MutationOptions<LoginGoogle_loginGoogle, unknown, LoginGoogleInput> = {
  mutationFn: async (input: LoginGoogleInput) => {
    const { data } = await loginGoogle({ input }, { show_alert: false });
    return data.loginGoogle;
  },
  mutationKey: 'loginGoogle',
};

export const connectGoogleWithAuthMutationOption: MutationOptions<
  ConnectGoogleWithAuth_connectGoogleWithAuth,
  unknown,
  ConnectGoogleWithAuthInput
> = {
  mutationFn: async (input: ConnectGoogleWithAuthInput) => {
    const { data } = await connectGoogleWithAuth({ input }, { show_alert: false });
    return data.connectGoogleWithAuth;
  },
  mutationKey: 'connectGoogleWithAuth',
};

export const signupGoogleMutationOption: MutationOptions<SignUpGoogle_signupGoogle, unknown, SignupGoogleInput> = {
  mutationFn: async (input: SignupGoogleInput) => {
    const { data } = await signUpGoogle({ input }, { show_alert: false });
    return data.signupGoogle;
  },
  mutationKey: 'signupGoogle',
};
