import { MutationOptions } from 'react-query';
import { connectAppleWithAuth, loginApple, signUpApple } from 'api2';
import {
  ConnectAppleWithAuth_connectAppleWithAuth,
  ConnectAppleWithAuthInput,
  LoginApple_loginApple,
  LoginAppleInput,
  SignUpApple_signupApple,
  SignupAppleInput,
} from 'api2/types';

export const loginAppleMutationOption: MutationOptions<LoginApple_loginApple, unknown, LoginAppleInput> = {
  mutationFn: async (input: LoginAppleInput) => {
    const { data } = await loginApple({ input }, { show_alert: false });
    return data.loginApple;
  },
  mutationKey: 'loginApple',
};

export const connectAppleWithAuthMutationOption: MutationOptions<
  ConnectAppleWithAuth_connectAppleWithAuth,
  unknown,
  ConnectAppleWithAuthInput
> = {
  mutationFn: async (input: ConnectAppleWithAuthInput) => {
    const { data } = await connectAppleWithAuth({ input }, { show_alert: false });
    return data.connectAppleWithAuth;
  },
  mutationKey: 'connectAppleWithAuth',
};

export const signupAppleMutationOption: MutationOptions<SignUpApple_signupApple, unknown, SignupAppleInput> = {
  mutationFn: async (input: SignupAppleInput) => {
    const { data } = await signUpApple({ input }, { show_alert: false });
    return data.signupApple;
  },
  mutationKey: 'signupApple',
};
