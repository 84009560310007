import { GetAppleLoginConfig_getAppleLoginConfig as AppleConfig } from 'api2/types';
import Script from 'components/toolbox/script';

import { getAppleRedirectURI } from 'util/auth/signin';

export const FacebookLoginScript = () => (
  <Script
    url='https://connect.facebook.net/en_US/sdk.js'
    onLoad={() => {
      const FB = window.FB;
      // 알파, 프로덕션 모두 동일한 app id 사용
      const app_id = '364119497109131';
      FB?.init({
        appId: app_id,
        cookie: true,
        xfbml: true,
        version: 'v14.0',
      });
    }}
  />
);

export const AppleLoginScript = ({ bundle_service_id, nonce }: AppleConfig) => (
  <Script
    url='https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
    onLoad={() => {
      const AppleID = window.AppleID;
      const redirectURI = getAppleRedirectURI();
      AppleID?.auth.init({
        clientId: bundle_service_id,
        scope: 'name email',
        redirectURI,
        nonce,
        state: bundle_service_id,
        usePopup: true,
      });
    }}
  />
);

export const GoogleLoginScript = () => <Script url='https://accounts.google.com/gsi/client' />;
export const TossLoginScript = () => <Script url='https://cdn.toss.im/oauth2/v1' />;
