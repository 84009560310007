import { useMutation } from 'react-query';
import { useAuthWebEvent } from 'components/auth/hooks/log';
import { useLoginErrors } from 'components/auth/hooks/login';
import { bundle_service_id_atom } from 'components/auth/store';
import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { loginAppleMutationOption } from 'queries/naver-shopping';

import { AuthInfo, AuthSessionStorage } from '@domains/auth-common';

import { Dialog } from 'util/Dialog';
import LoadingManager from 'util/loading_manager';
import { checkXSSUrl } from 'util/router';

interface AppleResponse {
  authorization?: Authorization;
  error?: string;
}

interface Authorization {
  code: string;
  id_token: string;
  state: string;
}

export const useAppleLogin = () => {
  const { mutateAsync: loginAppleMutate } = useMutation(loginAppleMutationOption);
  const { checkLoginError } = useLoginErrors();
  const store_bundle_id = useAtomValue(bundle_service_id_atom);
  const { push } = useRouter();
  const { addSocialLoginEvent } = useAuthWebEvent();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setEmail] = useSessionStorage('email', '');

  const loginWithApple = async (go_url: string) => {
    if (typeof window.AppleID === 'undefined') {
      return console.error('apple script가 존재하지 않습니다.');
    }

    let apple_code = '';

    try {
      const apple_response: AppleResponse = await window.AppleID.auth.signIn();

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!apple_response || !apple_response?.authorization || apple_response?.error) {
        Dialog.alertWithT('auth', {
          type: 'warning',
          title: '문제가 생겼어요',
          message: '잠시 후 다시 시도해주세요.\n문제가 지속되면 고객센터로 문의해주세요.',
        });
        return;
      }

      const { code } = apple_response.authorization;
      apple_code = code;

      LoadingManager.open();
      const { user_account } = await loginAppleMutate({ code, bundle_id: store_bundle_id });
      addSocialLoginEvent({ email: user_account.email, uuid: user_account.uuid }, 'apple');
      setEmail(user_account.email);
      push(checkXSSUrl(go_url));
    } catch (error) {
      const apple_error = error?.error;
      if (apple_error === 'popup_closed_by_user' || apple_error === 'user_cancelled_authorize') {
        return;
      }

      AuthSessionStorage.saveRedirectUrl(go_url);
      const auth_info: AuthInfo = {
        type: 'apple',
        code: apple_code,
        bundle_id: store_bundle_id,
      };
      checkLoginError(error, auth_info);
      LoadingManager.close();
    }
  };

  return { loginWithApple };
};
