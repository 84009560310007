import { ReactNode, useState } from 'react';
import { getAppleLoginConfig } from 'api2';
import {
  AppleLoginScript,
  FacebookLoginScript,
  GoogleLoginScript,
  TossLoginScript,
} from 'components/auth/common/Script';
import { createInitialValues } from 'components/auth/store';
import useInterval from 'hooks/useInterval';
import { Provider } from 'jotai';

export interface LoginBaseProps {
  children?: ReactNode;
  bundle_service_id: string;
  nonce: string;
}

export default function LoginBase({
  children,
  bundle_service_id: apple_bundle_id,
  nonce: apple_nonce,
}: LoginBaseProps) {
  const [apple_config, setAppleConfig] = useState({ bundle_service_id: apple_bundle_id, nonce: apple_nonce });
  const [fetch_times, setFetchTimes] = useState(1);

  useInterval(
    async () => {
      try {
        const {
          data: {
            getAppleLoginConfig: { bundle_service_id, nonce },
          },
        } = await getAppleLoginConfig(null, { show_alert: false });
        setAppleConfig({ bundle_service_id, nonce });
      } catch (error) {
        console.error(error);
      } finally {
        setFetchTimes((time) => time + 1);
      }
    },
    apple_config.nonce || fetch_times > 6 ? null : 300,
  );

  return (
    <Provider
      initialValues={createInitialValues({
        bundle_service_id: apple_config.bundle_service_id,
        nonce: apple_config.nonce,
      })}
    >
      <FacebookLoginScript />
      {apple_config.bundle_service_id && apple_config.nonce && (
        <AppleLoginScript bundle_service_id={apple_config.bundle_service_id} nonce={apple_config.nonce} />
      )}
      <GoogleLoginScript />
      <TossLoginScript />
      {children}
    </Provider>
  );
}
