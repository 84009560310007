import { MutationOptions } from 'react-query';
import { connectTossWithAuth, loginToss, signUpToss } from 'api2';
import {
  ConnectTossWithAuth_connectTossWithAuth,
  ConnectTossWithAuthInput,
  LoginToss_loginToss,
  LoginTossInput,
  SignUpToss_signupToss,
  SignupTossInput,
} from 'api2/types';

export const loginTossMutationOption: MutationOptions<LoginToss_loginToss, unknown, LoginTossInput> = {
  mutationFn: async (input: LoginTossInput) => {
    const { data } = await loginToss({ input }, { show_alert: false });
    return data.loginToss;
  },
  mutationKey: 'loginToss',
};

export const connectTossWithAuthMutationOption: MutationOptions<
  ConnectTossWithAuth_connectTossWithAuth,
  unknown,
  ConnectTossWithAuthInput
> = {
  mutationFn: async (input: ConnectTossWithAuthInput) => {
    const { data } = await connectTossWithAuth({ input }, { show_alert: false });
    return data.connectTossWithAuth;
  },
  mutationKey: 'connectTossWithAuth',
};

export const signupTossMutationOption: MutationOptions<SignUpToss_signupToss, unknown, SignupTossInput> = {
  mutationFn: async (input: SignupTossInput) => {
    const { data } = await signUpToss({ input }, { show_alert: false });
    return data.signupToss;
  },
  mutationKey: 'signupToss',
};
