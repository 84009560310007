import { useEffect } from 'react';

interface ScriptAttributes {
  integrity?: string;
  crossOrigin?: string | null;
  referrerPolicy?: string;
}

type ScriptAttributesKey = keyof ScriptAttributes;

function isScriptAttributesKey(key: string): key is ScriptAttributesKey {
  return ['integrity', 'crossOrigin', 'referrerPolicy'].includes(key);
}

interface Props {
  url: string;
  attributes?: ScriptAttributes;
  onLoad?: () => void;
  onError?: () => void;
}

const Script = (props: Props) => {
  const { url, attributes, onLoad, onError } = props;

  let count = 0;
  let scriptLoaderId = `id${count++}`;
  let loadedScripts = {};
  let erroredScripts = {};
  let scriptObservers = {};

  const createScript = () => {
    const script = document.createElement('script');

    if (attributes) {
      Object.keys(attributes)
        .filter(isScriptAttributesKey)
        .forEach((key) => {
          const attribute_value = attributes[key];
          if (attribute_value) {
            script.setAttribute(key, attribute_value);
          }
        });
    }

    script.src = url;

    if (!script.hasAttribute('async')) {
      script.async = true;
    }

    const callObserverFuncAndRemoveObserver = (shouldRemoveObserver) => {
      const observers = scriptObservers[url];
      Object.keys(observers).forEach((key) => {
        if (shouldRemoveObserver(observers[key])) {
          delete scriptObservers[url][scriptLoaderId];
        }
      });
    };

    script.onload = () => {
      loadedScripts[url] = true;
      callObserverFuncAndRemoveObserver((observer) => {
        observer.onLoad?.();
        return true;
      });
    };

    script.onerror = () => {
      erroredScripts[url] = true;
      callObserverFuncAndRemoveObserver((observer) => {
        observer.onError?.();
        return true;
      });
    };

    document.body.appendChild(script);
  };

  useEffect(() => {
    if (loadedScripts[url]) {
      onLoad?.();
      return;
    }

    if (erroredScripts[url]) {
      onError?.();
      return;
    }

    if (scriptObservers[url]) {
      scriptObservers[url][scriptLoaderId] = props;
      return;
    }

    scriptObservers[url] = { [scriptLoaderId]: props };

    createScript();

    return () => {
      const observers = scriptObservers[url];

      if (observers) {
        delete observers[scriptLoaderId];
      }
    };
  }, []);

  return null;
};

export default Script;
