import { useMutation } from 'react-query';
import { useAuthWebEvent } from 'components/auth/hooks/log';
import { useLoginErrors } from 'components/auth/hooks/login';
import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage';
import { useRouter } from 'next/router';
import { loginFacebookMutationOption } from 'queries/naver-shopping';

import { AuthInfo, AuthSessionStorage } from '@domains/auth-common';

import LoadingManager from 'util/loading_manager';
import { checkXSSUrl } from 'util/router';

interface FBInitInfo {
  appId: string;
  cookie?: Boolean;
  xfbml: Boolean;
  version: string;
}

type Status = 'connected' | 'not_authorized' | 'unknown';

interface AuthResponse {
  accessToken: string;
  data_access_expiration_time: number;
  expiresIn: number;
  graphDomain: string;
  signedRequest: string;
  userID: string;
  email?: string;
}

interface Response {
  status: Status;
  authResponse?: AuthResponse;
}

interface Scope {
  scope: string;
  enable_profile_selector?: boolean;
  auth_type?: 'rerequest' | 'reauthenticate' | 'reauthorize';
}

declare global {
  interface Window {
    fbAsyncInit?: () => void;
    FB?: {
      init: (info: FBInitInfo) => void;
      login: (callback: (response: Response) => void, scope?: Scope) => void;
      api: (route: string, callback: (response: any) => void) => void;
    };
  }
}

export const useFacebookLogin = () => {
  const { push } = useRouter();
  const { mutateAsync: loginFacebookMutate } = useMutation(loginFacebookMutationOption);
  const { checkLoginError } = useLoginErrors();
  const { addSocialLoginEvent } = useAuthWebEvent();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setEmail] = useSessionStorage('email', '');

  const loginWithFacebook = async (go_url: string) => {
    if (typeof window.FB === 'undefined') {
      return console.error('facebook sdk가 설치되어 있지 않습니다.');
    }

    window.FB.login(
      async (response) => {
        let access_token = '';
        try {
          if (response.status === 'connected' && response.authResponse) {
            const { accessToken } = response.authResponse;
            access_token = accessToken;
            LoadingManager.open();
            const { user_account } = await loginFacebookMutate({ access_token: accessToken });
            addSocialLoginEvent({ email: user_account.email, uuid: user_account.uuid }, 'facebook');
            setEmail(user_account.email);
            push(checkXSSUrl(go_url));
          }
        } catch (error) {
          AuthSessionStorage.saveRedirectUrl(go_url);
          const auth_info: AuthInfo = {
            type: 'facebook',
            access_token,
          };
          checkLoginError(error, auth_info);
          LoadingManager.close();
        }
      },
      { scope: 'public_profile,email', enable_profile_selector: true, auth_type: 'reauthenticate' },
    );
  };

  return { loginWithFacebook };
};
