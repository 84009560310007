export const getAppleRedirectURI = () => {
  const originUrl = window.location.origin;
  const isAlpha = originUrl.includes('alpha');
  const isDev = originUrl.includes('dev');
  const isBeta = originUrl.includes('beta');
  if (isAlpha) {
    return 'https://alpha.zigzag.kr/auth/login';
  } else if (isDev) {
    return 'https://dev-web-a.zigzag.kr/auth/login';
  } else if (isBeta) {
    return 'https://beta.zigzag.kr/auth/login';
  } else {
    return 'https://zigzag.kr/auth/login';
  }
};
