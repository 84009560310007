import { useMutation } from 'react-query';
import { useAuthWebEvent } from 'components/auth/hooks/log';
import { useLoginErrors } from 'components/auth/hooks/login';
import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage';
import { loginKakaoMutationOption } from 'queries/naver-shopping';

import { useKakaoAPI } from '@common/hooks';
import { AuthInfo, AuthSessionStorage } from '@domains/auth-common';

import { web_path } from 'constants/web_path';
import { AuthorizeKakaoScopeState } from 'util/auth/signin-scope';
import { checkXSSUrl } from 'util/router';

declare global {
  interface Window {
    Kakao?: {
      Auth: {
        /** (추가 항목 동의 받기)[https://developers.kakao.com/docs/latest/ko/kakaologin/js#additional-consent] */
        /** [parameters 자세히](https://developers.kakao.com/docs/latest/ko/kakaologin/rest-api#request-token) */
        login: (parameters: {
          scope?: string;
          success: (response: {
            access_token: string;
            expires_in: number;
            refresh_token: string;
            refresh_token_expires_in: number;
            scope?: string;
            token_type: string;
          }) => void;
          fail: (error: { error: string; error_description: string }) => void;
        }) => void;
        authorize: (parameters: { redirectUri: string; scope?: string; state?: string; throughTalk?: boolean }) => void;
      };
      init: (key: string) => void;
      isInitialized: () => boolean;
      Channel: {
        addChannel: (parameter: { channelPublicId: string }) => void;
      };
    };
  }
}

export const useKakaoLogin = (from_duplicate_account?: boolean) => {
  const { mutateAsync: loginKakaoMutate } = useMutation(loginKakaoMutationOption);
  const { addKakaoLoginEvent, addKakaoSignupEvent } = useAuthWebEvent();
  const { checkLoginError } = useLoginErrors();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setEmail] = useSessionStorage('email', '');
  useKakaoAPI();

  const loginWithKakao = (go_url: string) => {
    if (typeof window.Kakao === 'undefined') {
      return console.error('kakao가 설치되어 있지 않습니다.');
    }

    const kakao_state: AuthorizeKakaoScopeState = {
      redirect_uri: go_url,
      is_login: 'true',
      from_duplicate_account: from_duplicate_account ? 'true' : 'false',
    };
    window.Kakao.Auth.authorize({
      redirectUri: `${location.origin}${web_path.auth.redirect_scope}`,
      state: new URLSearchParams(kakao_state).toString(),
    });
  };

  const loginKakaoWithAuth = async (data: {
    redirect_url: string;
    access_token: string;
    refresh_token: string;
    from_login?: boolean;
    from_duplicate_account: boolean;
  }) => {
    const { redirect_url, access_token, refresh_token, from_duplicate_account } = data;
    try {
      const { is_created, uuid, email, user_account } = await loginKakaoMutate({
        access_token,
        refresh_token,
      });
      addKakaoLoginEvent(uuid, email);
      setEmail(email);
      if (is_created) {
        const plusFriendsStatus = user_account?.kakao_account?.plus_friends_status ?? null;
        addKakaoSignupEvent(uuid, plusFriendsStatus);
        const queryParams = new URLSearchParams({
          type: 'kakao',
          ...(redirect_url && { redirect: redirect_url }),
        });
        const url = `${web_path.auth.sign_up_done}?${queryParams.toString()}`;
        AuthSessionStorage.clearAllData();
        window.location.replace(url);
        return;
      }
      window.location.replace(checkXSSUrl(redirect_url));
    } catch (error) {
      AuthSessionStorage.saveRedirectUrl(data.redirect_url);
      const auth_info: AuthInfo = {
        type: 'kakao',
        access_token,
        refresh_token,
      };
      if (error?.extensions?.code === 'deleted_user_account_within_the_period') {
        const queryParams = new URLSearchParams({
          type: 'rejoin',
          rejoinPeriod: error?.extensions?.remaining_days,
        });

        const url = `${web_path.auth.error}?${queryParams.toString()}`;
        window.location.replace(url);
        return;
      }

      checkLoginError(error, auth_info, from_duplicate_account);
    }
  };

  return { loginWithKakao, loginKakaoWithAuth };
};
