import { GetAppleLoginConfig_getAppleLoginConfig as AppleConfig } from 'api2/types';
import { Atom, atom } from 'jotai';

export const bundle_service_id_atom = atom('');
export const nonce_atom = atom('');

export const createInitialValues = ({ bundle_service_id, nonce }: AppleConfig): Array<[Atom<string>, string]> => {
  return [
    [bundle_service_id_atom, bundle_service_id],
    [nonce_atom, nonce],
  ];
};
