import { MutationOptions } from 'react-query';
import { connectFacebookWithAuth, loginFacebook, signUpFacebook } from 'api2';
import {
  ConnectFacebookWithAuth_connectFacebookWithAuth,
  ConnectFacebookWithAuthInput,
  LoginFacebook_loginFacebook,
  LoginFacebookInput,
  SignUpFacebook_signupFacebook,
  SignupFacebookInput,
} from 'api2/types';

export const loginFacebookMutationOption: MutationOptions<LoginFacebook_loginFacebook, unknown, LoginFacebookInput> = {
  mutationFn: async (input: LoginFacebookInput) => {
    const { data } = await loginFacebook({ input }, { show_alert: false });
    return data.loginFacebook;
  },
  mutationKey: 'loginFacebook',
};

export const connectFacebookWithAuthMutationOption: MutationOptions<
  ConnectFacebookWithAuth_connectFacebookWithAuth,
  unknown,
  ConnectFacebookWithAuthInput
> = {
  mutationFn: async (input: ConnectFacebookWithAuthInput) => {
    const { data } = await connectFacebookWithAuth({ input }, { show_alert: false });
    return data.connectFacebookWithAuth;
  },
  mutationKey: 'connectFacebookWithAuth',
};

export const signupFacebookMutationOption: MutationOptions<
  SignUpFacebook_signupFacebook,
  unknown,
  SignupFacebookInput
> = {
  mutationFn: async (input: SignupFacebookInput) => {
    const { data } = await signUpFacebook({ input }, { show_alert: false });
    return data.signupFacebook;
  },
  mutationKey: 'signupFacebook',
};
