import React from 'react';
import ReactDom from 'react-dom';

import { Loading } from '@common/overlay-manager';

let loading_view_open_count: number = 0;
let container: HTMLDivElement | undefined;
let close_completed: boolean = true;

function createContainer() {
  if (!container) {
    container = document.createElement('div');
    document.body.appendChild(container);
  }
}

function unmountLoadingView() {
  close_completed = true;
  ReactDom.unmountComponentAtNode(container!);
}

const LoadingManager = {
  open: () => {
    if (typeof document !== 'undefined') {
      loading_view_open_count++;
      if (loading_view_open_count === 1) {
        createContainer();

        if (!close_completed) {
          unmountLoadingView();
        }

        const view = React.createElement(Loading);
        ReactDom.render(view, container!);
      }
    }
  },
  close: () => {
    if (loading_view_open_count > 0 && container) {
      loading_view_open_count--;

      close_completed = false;
      setTimeout(() => unmountLoadingView(), 600);
    }
  },
};
export default LoadingManager;
