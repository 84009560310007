import { useMutation } from 'react-query';
import { TOSS_REDIRECT_SCOPE } from 'components/auth/common/constants';
import { useAuthWebEvent } from 'components/auth/hooks/log';
import { useLoginErrors } from 'components/auth/hooks/login';
import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

import { web_path } from '@common/constants';
import { AuthInfo, AuthSessionStorage } from '@domains/auth-common';

import { loginTossMutationOption } from './hook/toss';

import LoadingManager from 'util/loading_manager';
import { checkXSSUrl } from 'util/router';

interface TossOauthError {
  error: string;
  error_description?: string;
}

interface TossOauthOption {
  type: 'popup' | 'push' | 'appOpen';
  client_id: string;
  redirect_uri: string;
  onSuccess?: (response: any) => void;
  onFail?: (error: TossOauthError) => void;
  oauth_policy: string;
  state?: string;
  grant_type?: string;
  _env_type?: string;
}

declare global {
  interface Window {
    TossOauth?: () => {
      start: (option: TossOauthOption) => void;
    };
  }
}

export const useTossLogin = (from_duplicate_account?: boolean) => {
  const { publicRuntimeConfig } = getConfig();
  const { toss_client_id, stage } = publicRuntimeConfig.config;
  const { replace } = useRouter();
  const { mutateAsync: loginTossMutate } = useMutation(loginTossMutationOption);
  const { checkLoginError } = useLoginErrors();
  const { addSocialLoginEvent, addSocialSignupEvent } = useAuthWebEvent();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setEmail] = useSessionStorage('email', '');

  const loginWithToss = (go_url: string) => {
    try {
      const toss_state = {
        redirect_uri: go_url,
        from_duplicate_account: from_duplicate_account ? 'true' : 'false',
      };

      const tossOauth = window.TossOauth?.();
      tossOauth?.start({
        type: 'push',
        client_id: toss_client_id || '',
        redirect_uri: `${location.origin}/${TOSS_REDIRECT_SCOPE}`,
        state: encodeURIComponent(JSON.stringify(toss_state)),
        _env_type: stage !== 'production' && stage !== 'beta' ? 'alpha' : 'product',
        oauth_policy: 'LOGIN',
      });
    } catch (error) {
      console.error('toss login error', error);
    }
  };

  const loginTossWithAuth = async (data: { code: string; redirect_url: string; from_duplicate_account: boolean }) => {
    const { code, redirect_url, from_duplicate_account } = data;
    try {
      LoadingManager.open();
      const { is_created, user_account } = await loginTossMutate({
        code,
        redirect_uri: `${location.origin}/${TOSS_REDIRECT_SCOPE}`,
      });
      addSocialLoginEvent({ email: user_account.email, uuid: user_account.uuid }, 'toss');
      setEmail(user_account.email);
      if (is_created) {
        addSocialSignupEvent({ email: user_account.email, uuid: user_account.uuid }, 'toss');
        const queryParams = new URLSearchParams({
          type: 'toss',
          ...(redirect_url && { redirect: redirect_url }),
        });
        const url = `${web_path.auth.sign_up_done}?${queryParams.toString()}`;
        AuthSessionStorage.clearAllData();
        window.location.replace(url);
        return;
      }
      replace(checkXSSUrl(redirect_url));
    } catch (error) {
      AuthSessionStorage.saveRedirectUrl(redirect_url);
      const auth_info: AuthInfo = {
        type: 'toss',
        code,
      };
      checkLoginError(error, auth_info, from_duplicate_account);
    } finally {
      LoadingManager.close();
    }
  };

  // 웹 sdk를 사용하지 않고 토스 인앱에서 직접 로그인 해야하는 상황이라 임시로 만듬
  const loginTossWithAuthOnTossInApp = async (data: {
    code: string;
    redirect_uri: string;
    from_duplicate_account: boolean;
    authorization_uri: string;
  }) => {
    const { code, redirect_uri, from_duplicate_account, authorization_uri } = data;
    try {
      LoadingManager.open();
      const { is_created, user_account } = await loginTossMutate({
        code,
        redirect_uri: authorization_uri,
      });
      addSocialLoginEvent({ email: user_account.email, uuid: user_account.uuid }, 'toss');
      setEmail(user_account.email);
      if (is_created) {
        addSocialSignupEvent({ email: user_account.email, uuid: user_account.uuid }, 'toss');
      }
      AuthSessionStorage.clearAllData();
      replace(checkXSSUrl(redirect_uri));
    } catch (error) {
      AuthSessionStorage.saveRedirectUrl(redirect_uri);
      const auth_info: AuthInfo = {
        type: 'toss',
        code,
      };
      checkLoginError(error, auth_info, from_duplicate_account);
    } finally {
      LoadingManager.close();
    }
  };

  return { loginWithToss, loginTossWithAuth, loginTossWithAuthOnTossInApp };
};
